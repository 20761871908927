import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDepartments: (ctx, q) => axiosIns.get(`/api.department${q || ''}`),
    fetchDepartment: (ctx, { id }) => axiosIns.get(`/api.department/${id}`),
    createDepartment: (ctx, data) => axiosIns.post('/api.department', data),
    updateDepartment: (ctx, data) => axiosIns.put(`/api.department/${data.id}`, data),
    deleteDepartment: (ctx, { id }) => axiosIns.delete(`/api.department/${id}`),
  },
}
