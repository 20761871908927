<template>
  <b-form-group>
    <label :for="labelFor">
      {{ label }}
      <span
        v-if="rules.includes('required')"
        class="text-danger"
      >*</span>
    </label>
    <validation-provider
      #default="{ errors }"
      :name="'provider-n-' + vid"
      :rules="rules"
      :vid="'provider-v-' + vid"
      class="position-relative d-block"
    >
      <b-form-input
        v-if="type==='input'"
        :id="'input-' + labelFor"
        :type="inputType"
        :value="value"
        :state="errors && errors.length > 0 ? false:null"
        :name="label"
        :placeholder="placeholder"
        @input="updateValue"
      />
      <b-form-select
        v-if="type==='select'"
        :id="'select-' + labelFor"
        :value="value"
        :options="selectOptions"
        :label-field="labelField"
        :value-field="valueField"
        :text-field="textField"
        :required="rules.includes('required')"
        :state="errors && errors.length > 0 ? false:null"
        :reduce="val => val[valueField]"
        @input="updateValue"
      >
        <template #first>
          <b-form-select-option
            value=""
            disabled
          >
            Chọn {{ label }}
          </b-form-select-option>
        </template>
      </b-form-select>
      <small
        v-if="isShowErrorText(errors && errors[0] || '')"
        class="text-danger text-error"
      >{{ getErrorText(errors && errors[0] || '') }}</small>
    </validation-provider>
  </b-form-group>

</template>

<script>

/* eslint-disable global-require */
import { ValidationProvider } from 'vee-validate'
import {
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
} from 'bootstrap-vue'
import { computed } from '@vue/composition-api'

export default {
  components: {
    BFormGroup,
    BFormInput,
    ValidationProvider,
    BFormSelect,
    BFormSelectOption,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    selectOptions: {
      type: Array,
      default: null,
    },
    labelField: {
      type: String,
      default: 'label',
    },
    valueField: {
      type: String,
      default: 'value',
    },
    textField: {
      type: String,
      default: 'text',
    },
    inputType: {
      type: String,
      default: 'text',
    },
    type: {
      type: String,
      default: 'input',
    },
    vid: {
      type: String,
      default: '',
    },
    customVError: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
    }
  },
  methods: {
    updateValue(val) {
      this.$emit('update', val)
    },
  },
  setup(props) {
    const labelFor = computed(() => `label-for-${props.vid}`)
    const getErrorText = e => (e.includes('custom-error:') ? e.replace('custom-error', '') : props.customVError)
    const isShowErrorText = (e = '') => (!e.includes('custom-error:') ? e.length : (e.replace('custom-error:', '') || '').length)
    return {
      labelFor,
      getErrorText,
      isShowErrorText,
    }
  },
}
</script>
